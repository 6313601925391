import React from 'react';
import Hero from "./_React/ui/Hero/Hero.jsx";
import KeyFeatures2 from "./_React/ui/KeyFeatures2/KeyFeatures2.jsx";
import DnaAnimation from "./_React/ui/DnaAnimation/DnaAnimation";


function App() {
  return (
    <div>
        <DnaAnimation/>
    </div>
  );
}

export default App;
